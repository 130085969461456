$(document).ready(function(){
  $('.product_image').click(function(e){
    $('#back').css({
      'width':$(window).width(),
      'height':$(document).height()
    }).show();

    var selector = $(this).next().next();
    selector.css({
      'position':'absolute',
      'left'  : Math.floor(($(window).width() - 500) / 2) + 'px',
      'top':$(window).scrollTop() + 50 + 'px'
    }).fadeIn();
  });

  //拡大表示クリック
  $("[id^=product_big_image], #back").click(function(){
    $("[id^=product_big_image]").fadeOut('', function(){
      $('#back').hide();
    });
  });

});
